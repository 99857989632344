import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Card} from "react-bootstrap"
import Img from "gatsby-image"

class Facilities extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const shopImage = data.shopImage.childImageSharp.fluid
    const restaurantImage = data.restaurantImage.childImageSharp.fluid
    const conferenceImage = data.conferenceImage.childImageSharp.fluid

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Facilities at Capernwray" />
        <Container>
          <Row>
            <Col sm={12}>
            <h1 className="page-title my-4">Facilities at the Dive Site</h1>
              <Card className='mb-5'>
                <Card.Header>
                  <h2>The Diving Centre Shop</h2>
                </Card.Header>
                  <Img alt='Shop at Capernwray' fluid={shopImage} />
                <Card.Body>
                  <p>
                  We stock a complete range of diving gear, with representations from the following manufacturers: Apeks, Cressi-Sub, Oceanic, Aqua Lung, Beaver, Mares Seamann Sub, Dive Rite, Sea and Sea Ltd, TUSA, Suunto, Hydrotech, Markat, Namron, Helios, Zeagle, Bowstone, Lumb Bros, Dacor,Hollis, Northern Diver, Body Glove, C Bear, Sub Sea, PADI materials etc. As always, we have a full range of DIR accessories, bolt snaps, Turtle fins, custom LP regulator hoses, Apeks DS4 first Stages ect.
                  </p>
                  <p>
                  We also have the best selection of dive wear in the UK. We have T shirts and sweats from Fourth Element, Sea Dog, PADI divewear, IQ, Weird Fish, and Body Glove . We also stock adventure wear from Patagonia.
                  </p>
                  <p>
                  The staff in the shop can provide expert advice on all elements of gear, from rigging a backplate in DIR fashion, to the best size T shirt! Our reputation is for good advice, and we strive to uphold this proudly. Give us a call in to discuss your needs.
                  </p>
                </Card.Body>
              </Card>
              </Col>
              <Col sm={6}>
                <Card className='mb-5'>
                  <Card.Header>
                    <h2>Porthole Restaurant</h2>
                  </Card.Header>
                    <Img alt='Porthouse Restaurant at Capernwray' fluid={restaurantImage} />
                  <Card.Body>
                    <p>
                    The Porthole is open every day and provides a great selection of hot and cold food. Our chef Kate and her team slave away to keep divers ravenous appetites satisfied! It has a fully licensed bar, with draught real ale and lager, as well as soft drinks, bottled bears, spirits and filter coffee. Celebrate passing your diving course in style! We can even do cappuccino and speciality coffee if required.
                    </p>
                    <p>
                    We don’t just serve divers either! Why not bring friends and family to watch you dive. They can sit in comfort, with an excellent view of your "goings on", drinking warm drinks or having a meal. The restaurants great position makes it a fascinating place to have a meal-when was the last time you were able to watch divers coming in and out of the water whilst enjoying a meal? Future plans include a video link into the water, so you will be able to watch the divers underwater as well.
                    </p>
                    <p>
                    The venue is available for special events, and we will happily entertain any ideas that you may have. We have hosted wedding receptions, conferences, Xmas parties and night dives! Let us know your plans, and we will endeavour to make your event a "splashing" success. The Porthole opening hours are the same as The Dive Centre. Last food orders are an hour before Dive Centre closing. Drinks served untill closing.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
                <Card className='mb-5'>
                  <Card.Header>
                    <h2>Conference Facilities</h2>
                  </Card.Header>
                    <Img alt='Conference Facilities at Capernwray' fluid={conferenceImage} />
                  <Card.Body>
                    <p>
                    The new conference room at Capernwray now allows clubs, dive schools, businesses etc. the opportunity to rent a modern, spacious meeting room, complete with the usual visual aids, that offers something above and beyond the ‘traditional’ hotel venue. The conference room can seat up to 30 delegates in either ‘boardroom’ style or lecture room layout and has full-length glass windows on one wall, allowing an uninterrupted view across the waters of the old quarry. This provides one of the most picturesque settings for a meeting imaginable! The room is available for hire during normal working hours, Monday to Friday.
                    </p>
                    <p>
                    The room can be booked at weekends but some prior notice will be required as it is often used as an ‘overspill’ for the restaurant on busy Saturdays and Sundays (please contact the Centre if you wish to discuss a weekend booking). We can offer a standard daily delegate rate, to include unlimited tea and coffee throughout the period of hire and buffet lunch, if required. We also offer ‘rental by the hour’ for smaller numbers or any combination of the two. We aim to be completely flexible so that we can offer you a package to suit your particular requirements.
                    </p>
                    <p>
                    For any non-divers amongst you, we are ideally placed to enhance your visit with an underwater theme, should you so wish! From simply watching the divers in the water from the private terrace, to having a ‘try a dive’ at the end of your meeting, we can make arrangements accordingly. We have a large private car park, discreetly screened from the public road and a team of expert, friendly staff to ensure that your visit is efficiently managed from start to finish. Our chef comes highly rated and our licensed bar and restaurant will be available for your use, should a drink after work prove beneficial!
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Facilities

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    shopImage: file(relativePath: { eq: "capernwray-shop.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    restaurantImage: file(relativePath: { eq: "capernwray-restaurant.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    conferenceImage: file(relativePath: { eq: "capernwray-conference.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
  }
`
